import * as CONTENT_GROUPS from "./contentGroups";
import * as PAGE_TYPES from "../Programmatic/pageTypes";

export default {
  [PAGE_TYPES.BARE]: CONTENT_GROUPS.LANDING_PAGE,
  [PAGE_TYPES.LEARN_ARTICLE]: CONTENT_GROUPS.LEARN_ARTICLE,
  [PAGE_TYPES.CITY_PAGE]: CONTENT_GROUPS.CITY_PAGE,
  [PAGE_TYPES.CITY_SUB_PAGE]: CONTENT_GROUPS.CITY_PAGE,
  [PAGE_TYPES.CITY_ATTORNEY_PRICING]: CONTENT_GROUPS.CITY_PAGE,
  [PAGE_TYPES.STATE_PAGE]: CONTENT_GROUPS.STATE_PAGE,
  [PAGE_TYPES.STATE_SUB_PAGE]: CONTENT_GROUPS.STATE_PAGE,
  [PAGE_TYPES.STATE_BANKRUPTCY_FORMS]: CONTENT_GROUPS.STATE_PAGE,
  [PAGE_TYPES.STATE_MEANS_TEST]: CONTENT_GROUPS.STATE_PAGE,
  [PAGE_TYPES.JOB_LISTING]: CONTENT_GROUPS.LANDING_PAGE,
};
