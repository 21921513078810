import { graphql } from "gatsby";
import Link from "gatsby-link";
import { get, startCase, sortBy } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { EVENTS, STATES } from "@upsolve/shared";
import { H1, H5, HR, Small } from "@upsolve/ui";

import * as CONTENT_CREATION_METHODS from "../components/analytics/contentCreationMethods";
import * as PAGE_TYPES from "../components/Programmatic/pageTypes";
import Article from "../components/Layout/Article.div";
import AuthorBio from "../components/Layout/AuthorBio";
import AuthorsBlock from "../components/Layout/AuthorsBlock";
import BreadcrumbNav from "../components/Layout/BreadcrumbNav";
import CTASideBySideOfferings from "../components/CTA/CTASideBySideOfferings";
import CitationsList from "../components/Layout/CitationsList";
import ContentSectionFooter from "../components/Layout/ContentSectionFooter";
import DisabledReadingPrompt from "../components/CTA/DisabledReadingPrompt";
import HeroFilerCta from "../components/CTA/HeroFilerCta";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import StateGuidesListPanel from "../components/Programmatic/StateGuidesListPanel";
import TableOfContents from "../components/Layout/TableOfContents";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import UpsolveTextBio from "../components/ProofBuilders/UpsolveTextBio";
import VideoInContext from "../components/Media/VideoInContext";
import checkForInlineCta from "../components/Contentful/checkForInlineCta";
import getCitationsFromRichText from "../components/Contentful/getCitationsFromRichText";
import getHeadersFromRichText from "../components/Contentful/getHeadersFromRichText";
import getWordCountFromRichText from "../components/Contentful/getWordCountFromRichText";
import pageTypeContentGroups from "../components/analytics/pageTypeContentGroups";
import richTextNodeToComponent from "../components/Contentful/richTextNodeToComponent";
import richTextNodesInSections from "../components/Contentful/richTextNodesInSections";
import { track } from "../components/analytics/track";
import withScrollWatcher from "../components/analytics/withScrollWatcher";
import { Ampify } from "../components/AMP/AmpContext";
import CTAUpsolveReviews from "../components/CTA/CTAUpsolveReviews";
import { learnTagsSortedByPriority } from "../components/Learn/learnTagPriority";
import { CTATarget } from "../components/CTA/getCTAData";
import { fixHeaderScrollOffset } from "../components/Layout/fixHeaderScrollOffset";

const templateProps = {
  pageComponentName: "PageTemplate",
  pageComponentVersion: "2",
};

const getArticleTarget = (tags: { value: string }[]) => {
  //future: use the graphQL query instead of this static list
  if (tags.find((tag) => tag.value === learnTagsSortedByPriority.STUDENT_LOANS)) {
    return CTATarget.STUDENT_LOANS;
  } else if (tags.find((tag) => tag.value === learnTagsSortedByPriority.CONSUMER_RIGHTS)) {
    return CTATarget.DEBT_TRIAGE;
  }
  return CTATarget.SCREENER;
};
/**
 * PageTemplate
 * - v0: Where we began.
 * - v1: Layout adjustments - condensed top, collapsed TOC, hide CTAs based on screen width
 * - v2: ContentSectionFooter removed, share button at top added, more intuitive Table of Contents
 *
 * @component
 * @version 2
 */
const PageTemplate = (props: any) => {
  const { pageContext, scrollWatcher } = props;
  const { content, createdAt, locality, seo, summary, title, type, updatedAt, video } = props.data.page;
  const { amp, districts } = pageContext;
  const layout = props.data.page.layout || {};
  const wordCount = getWordCountFromRichText(get(props, "data.page.content.json", []));
  // - Associated Page Data
  const clusterChildren = props.data.childrenPages.edges
    .map((e) => e.node)
    .filter((n) => !n.path.includes("/questions/"));
  const clusterSiblings = props.data.siblingPages.edges
    .map((e) => e.node)
    .filter((n) => !n.path.includes("/questions/"));
  // - Content
  const sectionHeaders = getHeadersFromRichText(content.json);
  const contentSections = richTextNodesInSections(content.json);
  const citations = getCitationsFromRichText(content.json);
  const hasInlineCta = checkForInlineCta(content.json);
  const authors = (get(props, "data.page.authors") || [get(props, "data.page.author")]).filter(
    (author) => author && author.name != null
  );
  const articleTags = props.data.page.tags || [];
  const trackingPageContext = {
    contentCreationMethod: get(props, "data.page.contentTraits.creationMethod", CONTENT_CREATION_METHODS.WRITTEN),
    contentGroup:
      get(props, "data.page.contentTraits.contentGroup") || get(pageTypeContentGroups, get(props, "data.page.type")),
    contentLocalized:
      get(props, "data.page.contentTraits.contentLocalized") || get(props, "data.page.locality") != null,
    publishedAt: get(props, "data.page.publishedAt"),
    searchTermTargets: [get(props, "data.page.contentTraits.searchTermTarget")],
    tags: articleTags.map((tag) => ({ tag: tag.value })),
    updatedAt: get(props, "data.page.updatedAt"),
    wordCount,
    ...templateProps,
  };

  const ctaTarget = getArticleTarget(articleTags);

  // On Mount
  useEffect(() => {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }, []);

  useEffect(() => {
    fixHeaderScrollOffset();
  }, []);

  // Comp
  const children = (
    <TopLevelLayout
      showPersistentCta={false}
      persistentCtaText={layout.persistentCtaText}
      showBackground={layout.showBackground}
      backgroundTemplate={layout.backgroundTemplate}
      trackingPageContext={trackingPageContext}
    >
      <SEO seoNode={seo} pageNode={props.data.page} />
      <MainHeader
        allowMobileFooter={type !== PAGE_TYPES.JOB_LISTING}
        trackingPageContext={trackingPageContext}
        ctaTarget={ctaTarget}
      />
      <Article styleType={type === PAGE_TYPES.JOB_LISTING ? "centered" : undefined}>
        <BreadcrumbNav />
        <div className="article__body">
          <div className="article__content">
            <div className="content-hero">
              <header className="content-hero__header" id="top">
                <H1>{title}</H1>
                {![PAGE_TYPES.BARE, PAGE_TYPES.JOB_LISTING].includes(type) && (
                  <Fragment>
                    <UpsolveTextBio
                      wordCount={wordCount}
                      trackingPageContext={trackingPageContext}
                      ctaTarget={ctaTarget}
                    />
                    <HR />
                  </Fragment>
                )}
              </header>
            </div>
            <div className={type === PAGE_TYPES.BARE ? `content-body full-width` : `content-body`}>
              <article className="content-body__container">
                {get(summary, "json") != null && (
                  <div className="content-body__lead">
                    <div className="summary">
                      <b>In a Nutshell</b>
                      {Array.prototype.map.call(summary.json.content, (c, i, a) =>
                        richTextNodeToComponent(c, i, a, { locality, trackingPageContext })
                      )}
                    </div>
                  </div>
                )}
                {type !== PAGE_TYPES.BARE && (
                  <AuthorsBlock authors={authors} createdAt={createdAt} updatedAt={updatedAt} />
                )}
                {type !== PAGE_TYPES.BARE && <HR />}
                {sectionHeaders.length > 0 && <TableOfContents sectionHeaders={sectionHeaders} />}
                {video != null && (
                  <section className="content-video">
                    <VideoInContext video={video} />
                  </section>
                )}
                {contentSections.length === 1
                  ? Array.prototype.map.call(contentSections[0], (c, i, a) =>
                      richTextNodeToComponent(c, i, a, { locality, districts, trackingPageContext })
                    )
                  : Array.prototype.map.call(contentSections, (section, sectionIndex, sectionArray) => (
                      <Fragment key={sectionIndex}>
                        <section>
                          {Array.prototype.map.call(section, (c, i, a) =>
                            richTextNodeToComponent(c, i, a, { locality, districts, trackingPageContext })
                          )}
                        </section>
                        {hasInlineCta === false && Math.floor(sectionArray.length * 0.35) === sectionIndex && (
                          <CTAUpsolveReviews
                            key={"cta reviews"}
                            dataTestId="inline-content-cta"
                            trackingPageContext={trackingPageContext}
                            ctaTarget={ctaTarget}
                          />
                        )}
                      </Fragment>
                    ))}
                <HR />
                {citations.length > 0 && (
                  <Fragment>
                    <H5 as="h2">Sources:</H5>
                    <CitationsList citations={citations} />
                  </Fragment>
                )}
                <ContentSectionFooter />
                <HR />
                {authors.length > 0 && (
                  <div className="author-bios">
                    <div className="written-by">
                      <Small>Written By:</Small>
                    </div>
                    {authors.map((author) => (
                      <AuthorBio key={author.name} author={author} />
                    ))}
                    <div className="team">
                      <Link to="/team">Read About the Upsolve Team</Link>
                    </div>
                  </div>
                )}
              </article>
              {![PAGE_TYPES.BARE, PAGE_TYPES.JOB_LISTING].includes(type) && (
                <aside className="article__sidebar">
                  {type !== PAGE_TYPES.JOB_LISTING && layout.showHeroCta !== false && (
                    <div className="sidebar-hero__cta">
                      {(layout.heroCtaType === "filer" || layout.heroCtaType == null) && (
                        <HeroFilerCta trackingPageContext={trackingPageContext} target={ctaTarget} />
                      )}
                    </div>
                  )}
                </aside>
              )}
            </div>
          </div>
        </div>
        <div className="related-content">
          {type !== PAGE_TYPES.JOB_LISTING &&
            type !== PAGE_TYPES.BARE &&
            (clusterSiblings.length > 0 || clusterChildren.length > 0) && (
              <section>
                <H5>Related Reading</H5>
                <ul>
                  {Array.prototype.map.call(sortBy([...clusterChildren, ...clusterSiblings]), (c, cIndex) => (
                    <li key={cIndex}>
                      <a href={c.path}>
                        {get(c, "context.title") ||
                          `${STATES[c.path.slice(1, 2)] || ""} ${startCase(
                            c.path.slice(3).replace(/\//g, " ")
                          )}`.trim()}
                      </a>
                    </li>
                  ))}
                </ul>
              </section>
            )}
        </div>
      </Article>
      {![PAGE_TYPES.BARE, PAGE_TYPES.JOB_LISTING].includes(type) && (
        <CTASideBySideOfferings trackingPageContext={trackingPageContext} ctaTarget={ctaTarget} />
      )}
      {[
        PAGE_TYPES.STATE_PAGE,
        PAGE_TYPES.STATE_BANKRUPTCY_FORMS,
        PAGE_TYPES.STATE_SUB_PAGE,
        PAGE_TYPES.STATE_MEANS_TEST,
      ].includes(type) && <StateGuidesListPanel />}
      <MainFooter />
      {type !== PAGE_TYPES.JOB_LISTING && layout.showDisabledReadingPrompt !== false && (
        <DisabledReadingPrompt
          isAllowedToRender={() => scrollWatcher.scrollPosition > scrollWatcher.documentHeight / 2}
          trackingPageContext={trackingPageContext}
          ctaTarget={ctaTarget}
        />
      )}
    </TopLevelLayout>
  );

  return amp === true ? <Ampify>{children}</Ampify> : children;
};

// TODO: Move SEO fragment to a StaticQuery within SEO.jsx
// query PageByPath($path: String!, $childrenPathRegex: String, $siblingPathRegex: String) {
export const pageQuery = graphql`
  query PageByPath(
    $canonicalPath: String!
    $childrenPathRegex: String
    $excludedChildrenPageTypes: [String]
    $excludedChildrenTemplates: String
    $siblingPathRegex: String
    $excludedSiblingPageTypes: [String]
    $excludedSiblingTemplates: String
  ) {
    page: contentfulPage(path: { eq: $canonicalPath }) {
      path
      title
      type
      content {
        json
      }
      summary {
        json
      }
      author {
        ...AuthorNode
      }
      createdAt
      updatedAt
      locality {
        level
        state
        city
      }
      tags {
        value
        label
      }
      contentTraits {
        contentCreationMethod
        contentGroup
        contentLocalized
        contentTopics
        searchTermTarget
      }
      layout {
        showPersistentCta
        showHeroCta
        showBackground
        showDisabledReadingPrompt
        persistentCtaText
        heroCtaType
        backgroundTemplate
      }
      video {
        ...VideoNode
      }
      seo {
        ...SeoNode
      }
    }

    childrenPages: allSitePage(
      filter: {
        path: { regex: $childrenPathRegex, ne: $canonicalPath }
        component: { regex: $excludedChildrenTemplates }
        context: { amp: { ne: true }, type: { nin: $excludedChildrenPageTypes } }
      }
    ) {
      edges {
        node {
          path
          component
          context {
            title
          }
        }
      }
    }

    siblingPages: allSitePage(
      filter: {
        path: { regex: $siblingPathRegex, ne: $canonicalPath }
        component: { regex: $excludedSiblingTemplates }
        context: { amp: { ne: true }, type: { nin: $excludedSiblingPageTypes } }
      }
    ) {
      edges {
        node {
          path
          component
          context {
            title
          }
        }
      }
    }
  }
`;

export default withScrollWatcher(PageTemplate);
