import Link from "gatsby-link";
import React from "react";
import styled from "styled-components";
import { ACSS, H4 } from "@upsolve/ui";

const AllStatesPanel = styled.div`
  background: ${(props) => props.theme.colors.white["800"]};
  div.prompt {
    margin-right: 1em;
    margin-bottom: 2em;
    max-width: 240px;
  }
  div.wrapper {
    display: flex;
    max-width: 1080px;
    margin: 0 auto;
    padding: 3em 1em;
    div.list {
      flex-grow: 1;
      margin: 0 0.5em;
      line-height: 110%;
      margin-bottom: 2em;
      @media screen and (max-width: 45em) {
        flex-direction: column;
        margin-bottom: 0;
        ul {
          margin: 0;
        }
      }
    }
    a {
      ${ACSS}
      font-size: 14px;
    }
    li {
      font-size: 0.9em;
      margin-bottom: 0.75em;
    }
    @media (max-width: 45em) {
      flex-direction: column;
    }
  }
  div.content {
    width: 100%;
    display: flex;
    @media screen and (max-width: 45em) {
      flex-direction: column;
    }
  }
`;

const AllStatesLinks = () => (
  <AllStatesPanel>
    <div className="wrapper">
      <div className="prompt">
        <H4>Bankruptcy guides by state</H4>
      </div>
      <div className="content">
        <div className="list">
          <ul>
            <li>
              <a href="/al/">Alabama</a>
            </li>
            <li>
              <a href="/ak/">Alaska</a>
            </li>
            <li>
              <a href="/ar/">Arkansas</a>
            </li>
            <li>
              <a href="/az/">Arizona</a>
            </li>
            <li>
              <a href="/ca/">California</a>
            </li>
            <li>
              <a href="/co/">Colorado</a>
            </li>
            <li>
              <a href="/ct/">Connecticut</a>
            </li>
            <li>
              <a href="/dc/">District of Columbia</a>
            </li>
            <li>
              <a href="/de/">Delaware</a>
            </li>
            <li>
              <a href="/fl/">Florida</a>
            </li>
            <li>
              <a href="/ga/">Georgia</a>
            </li>
          </ul>
        </div>
        <div className="list">
          <ul>
            <li>
              <a href="/hi/">Hawaii</a>
            </li>
            <li>
              <a href="/ia/">Iowa</a>
            </li>
            <li>
              <a href="/id/">Idaho</a>
            </li>
            <li>
              <a href="/il/">Illinois</a>
            </li>
            <li>
              <a href="/in/">Indiana</a>
            </li>
            <li>
              <a href="/ks/">Kansas</a>
            </li>
            <li>
              <a href="/ky/">Kentucky</a>
            </li>
            <li>
              <a href="/la/">Louisiana</a>
            </li>
            <li>
              <a href="/ma/">Massachusetts</a>
            </li>
            <li>
              <a href="/md/">Maryland</a>
            </li>
            <li>
              <a href="/me/">Maine</a>
            </li>
          </ul>
        </div>
        <div className="list">
          <ul>
            <li>
              <a href="/mi/">Michigan</a>
            </li>
            <li>
              <a href="/mn/">Minnesota</a>
            </li>
            <li>
              <a href="/mo/">Missouri</a>
            </li>
            <li>
              <a href="/ms/">Mississippi</a>
            </li>
            <li>
              <a href="/mt/">Montana</a>
            </li>
            <li>
              <a href="/nc/">North Carolina</a>
            </li>
            <li>
              <a href="/nd/">North Dakota</a>
            </li>
            <li>
              <a href="/ne/">Nebraska</a>
            </li>
            <li>
              <a href="/nh/">New Hampshire</a>
            </li>
            <li>
              <a href="/nj/">New Jersey</a>
            </li>
            <li>
              <a href="/nm/">New Mexico</a>
            </li>
          </ul>
        </div>
        <div className="list">
          <ul>
            <li>
              <a href="/nv/">Nevada</a>
            </li>
            <li>
              <a href="/ny/">New York</a>
            </li>
            <li>
              <a href="/oh/">Ohio</a>
            </li>
            <li>
              <a href="/ok/">Oklahoma</a>
            </li>
            <li>
              <a href="/or/">Oregon</a>
            </li>
            <li>
              <a href="/pa/">Pennsylvania</a>
            </li>
            <li>
              <a href="/ri/">Rhode Island</a>
            </li>
            <li>
              <a href="/sc/">South Carolina</a>
            </li>
            <li>
              <a href="/sd/">South Dakota</a>
            </li>
            <li>
              <a href="/tn/">Tennessee</a>
            </li>
            <li>
              <a href="/tx/">Texas</a>
            </li>
          </ul>
        </div>
        <div className="list">
          <ul>
            <li>
              <a href="/ut/">Utah</a>
            </li>
            <li>
              <a href="/va/">Virginia</a>
            </li>
            <li>
              <a href="/vt/">Vermont</a>
            </li>
            <li>
              <a href="/wa/">Washington</a>
            </li>
            <li>
              <a href="/wi/">Wisconsin</a>
            </li>
            <li>
              <a href="/wv/">West Virginia</a>
            </li>
            <li>
              <a href="/wy/">Wyoming</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </AllStatesPanel>
);

export default AllStatesLinks;
